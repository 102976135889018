<script setup lang="ts"></script>

<template>
    <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_521_11314)">
            <path
                d="M1.62261 8.69052C1.6461 8.80736 1.70827 8.91288 1.7991 8.99005L12.14 17.7397L14.3653 15.11L10.8989 11.6465C10.8471 11.5982 10.8056 11.54 10.7767 11.4753C10.7479 11.4106 10.7324 11.3408 10.7312 11.2699C10.7299 11.1991 10.743 11.1288 10.7695 11.0631C10.796 10.9975 10.8355 10.9378 10.8856 10.8878C10.9356 10.8377 10.9953 10.7982 11.0609 10.7717C11.1266 10.7452 11.1969 10.7321 11.2677 10.7334C11.3385 10.7346 11.4084 10.7501 11.4731 10.779C11.5378 10.8078 11.596 10.8493 11.6443 10.9011L15.0474 14.3022L17.3086 11.6303L8.99066 1.79848C8.91349 1.70766 8.80797 1.64549 8.69113 1.622L0.633314 0.010438C0.547888 -0.00702284 0.459465 -0.00302918 0.37596 0.0220615C0.292456 0.0471522 0.216476 0.0925569 0.154822 0.154211C0.0931672 0.215866 0.0477625 0.291846 0.0226719 0.37535C-0.00241883 0.458855 -0.00641249 0.547277 0.0110484 0.632704L1.62261 8.69052Z"
                fill="url(#paint0_linear_521_11314)"
            />
            <path
                d="M6.99439 20.5115C7.53704 20.5115 8.05755 20.7267 8.44177 21.1099L14.8901 27.5583C15.2733 27.9425 15.4885 28.463 15.4885 29.0057C15.4885 29.5483 15.2733 30.0688 14.8901 30.453L13.8151 31.5281C13.7161 31.6267 13.5821 31.6821 13.4424 31.6821C13.3027 31.6821 13.1687 31.6267 13.0697 31.5281L4.47193 22.9303C4.37334 22.8313 4.31799 22.6973 4.31799 22.5576C4.31799 22.418 4.37334 22.284 4.47193 22.185L5.547 21.1099C5.93122 20.7267 6.45173 20.5115 6.99439 20.5115Z"
                fill="url(#paint1_linear_521_11314)"
            />
            <path
                d="M31.5285 22.185C31.6271 22.2839 31.6825 22.4179 31.6824 22.5576C31.6825 22.6974 31.6271 22.8314 31.5285 22.9303L22.9306 31.5281C22.8317 31.6267 22.6977 31.6821 22.558 31.6821C22.4183 31.6821 22.2843 31.6267 22.1853 31.5281L21.1103 30.453C20.727 30.0688 20.5118 29.5483 20.5118 29.0057C20.5118 28.463 20.727 27.9425 21.1103 27.5583L27.5586 21.1099C27.9428 20.7267 28.4633 20.5115 29.006 20.5115C29.5486 20.5115 30.0692 20.7267 30.4534 21.1099L31.5285 22.185Z"
                fill="url(#paint2_linear_521_11314)"
            />
            <path
                d="M26.9443 29.0054L29.0052 26.9445L32.3682 30.3048C31.8956 30.4793 31.4665 30.7542 31.1102 31.1104C30.754 31.4667 30.4791 31.8959 30.3046 32.3685L26.9443 29.0054Z"
                fill="url(#paint3_linear_521_11314)"
            />
            <path
                d="M35.291 31.8579C34.9517 31.519 34.5194 31.2882 34.0489 31.1949C33.5785 31.1015 33.0909 31.1497 32.6478 31.3334C32.2047 31.5171 31.826 31.828 31.5596 32.2269C31.2932 32.6257 31.151 33.0946 31.151 33.5742C31.151 34.0539 31.2932 34.5228 31.5596 34.9216C31.826 35.3205 32.2047 35.6314 32.6478 35.8151C33.0909 35.9988 33.5785 36.047 34.0489 35.9536C34.5194 35.8603 34.9517 35.6295 35.291 35.2906C35.7456 34.835 36.0008 34.2178 36.0008 33.5742C36.0008 32.9307 35.7456 32.3135 35.291 31.8579Z"
                fill="url(#paint4_linear_521_11314)"
            />
            <path
                d="M3.63245 30.3048C4.10505 30.4793 4.53424 30.7542 4.89048 31.1104C5.24673 31.4667 5.52157 31.8959 5.69612 32.3685L9.05846 29.0054L6.99549 26.9445L3.63245 30.3048Z"
                fill="url(#paint5_linear_521_11314)"
            />
            <path
                d="M0.0458966 33.1004C0.139539 32.6296 0.370693 32.1971 0.710128 31.8577C1.16586 31.4035 1.78304 31.1485 2.42646 31.1485C3.06987 31.1485 3.68705 31.4035 4.14278 31.8577C4.48222 32.1971 4.71337 32.6296 4.80702 33.1004C4.90066 33.5713 4.85258 34.0593 4.66887 34.5028C4.48516 34.9463 4.17406 35.3254 3.77492 35.5921C3.37577 35.8588 2.9065 36.0011 2.42646 36.0011C1.94641 36.0011 1.47714 35.8588 1.078 35.5921C0.678851 35.3254 0.367752 34.9463 0.18404 34.5028C0.000328124 34.0593 -0.0477461 33.5713 0.0458966 33.1004Z"
                fill="url(#paint6_linear_521_11314)"
            />
            <path
                d="M25.6678 21.5084L23.9613 23.2156L21.6973 20.9516L23.7363 19.2261L25.6678 21.5084Z"
                fill="url(#paint7_linear_521_11314)"
            />
            <path
                d="M23.2161 23.9613L20.8902 21.6354L18.8167 23.3897L21.5096 25.6678L23.2161 23.9613Z"
                fill="url(#paint8_linear_521_11314)"
            />
            <path
                d="M24.3538 10.8986L12.0393 23.2159L10.3363 21.5095L27.0102 1.79876C27.0874 1.70794 27.1929 1.64576 27.3097 1.62228L35.3676 0.0107127C35.453 -0.00674818 35.5414 -0.00275452 35.6249 0.0223362C35.7084 0.0474269 35.7844 0.0928315 35.846 0.154486C35.9077 0.21614 35.9531 0.29212 35.9782 0.375625C36.0033 0.459129 36.0073 0.547552 35.9898 0.632978L34.3783 8.69079C34.3548 8.80763 34.2926 8.91315 34.2018 8.99032L14.4911 25.6677L12.7846 23.9613L25.0991 11.6439C25.1923 11.5439 25.243 11.4117 25.2406 11.2751C25.2382 11.1385 25.1828 11.0081 25.0862 10.9115C24.9896 10.8149 24.8592 10.7596 24.7226 10.7572C24.586 10.7547 24.4538 10.8055 24.3538 10.8986Z"
                fill="url(#paint9_linear_521_11314)"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_521_11314"
                x1="18"
                y1="-0.000244141"
                x2="18"
                y2="36.0011"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#2AACF5" />
                <stop offset="1" stop-color="#2770B3" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_521_11314"
                x1="18"
                y1="-0.000244141"
                x2="18"
                y2="36.0011"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#2AACF5" />
                <stop offset="1" stop-color="#2770B3" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_521_11314"
                x1="18"
                y1="-0.000244141"
                x2="18"
                y2="36.0011"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#2AACF5" />
                <stop offset="1" stop-color="#2770B3" />
            </linearGradient>
            <linearGradient
                id="paint3_linear_521_11314"
                x1="18"
                y1="-0.000244141"
                x2="18"
                y2="36.0011"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#2AACF5" />
                <stop offset="1" stop-color="#2770B3" />
            </linearGradient>
            <linearGradient
                id="paint4_linear_521_11314"
                x1="18"
                y1="-0.000244141"
                x2="18"
                y2="36.0011"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#2AACF5" />
                <stop offset="1" stop-color="#2770B3" />
            </linearGradient>
            <linearGradient
                id="paint5_linear_521_11314"
                x1="18"
                y1="-0.000244141"
                x2="18"
                y2="36.0011"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#2AACF5" />
                <stop offset="1" stop-color="#2770B3" />
            </linearGradient>
            <linearGradient
                id="paint6_linear_521_11314"
                x1="18"
                y1="-0.000244141"
                x2="18"
                y2="36.0011"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#2AACF5" />
                <stop offset="1" stop-color="#2770B3" />
            </linearGradient>
            <linearGradient
                id="paint7_linear_521_11314"
                x1="18"
                y1="-0.000244141"
                x2="18"
                y2="36.0011"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#2AACF5" />
                <stop offset="1" stop-color="#2770B3" />
            </linearGradient>
            <linearGradient
                id="paint8_linear_521_11314"
                x1="18"
                y1="-0.000244141"
                x2="18"
                y2="36.0011"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#2AACF5" />
                <stop offset="1" stop-color="#2770B3" />
            </linearGradient>
            <linearGradient
                id="paint9_linear_521_11314"
                x1="18"
                y1="-0.000244141"
                x2="18"
                y2="36.0011"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#2AACF5" />
                <stop offset="1" stop-color="#2770B3" />
            </linearGradient>
            <clipPath id="clip0_521_11314">
                <rect width="36" height="36" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
